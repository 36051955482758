export const environment = {
    production: false,

    BASE_API: "https://dev.api.sso.wginb.com",
    LOGIN_ENDPOINT: "https://dev.api.sso.wginb.com/ssologinv2/login",
    ADD_USER_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/user",
    GET_USER_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/user/{user}",
    GET_USER_LIST_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/user/list",
    GET_STATIC_DATA_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/static",
    UPDATE_USER_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/user/{user}",
    RESET_USER_PASSWORD_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/user/{user}",
    DELETE_USER_ENDPOINT: "https://dev.api.sso.wginb.com/ssov2/user/{user}",
    RESET_PASSWORD: "https://dev.portal.reset.wginb.com"
};
