import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { LoginRequest } from "../models/external/login-request.model";
import { LoginResponse } from "../models/external/login-response.model";
import { environment } from "src/environments/environment";
import { JwtToken } from "../models/jwt-token.model";
import jwt_decode from "jwt-decode";
import { Injectable } from "@angular/core";
import { StaticDataResponse } from "../models/external/static-data-response.model";
import { StaticData } from "../models/static-data.model";

export const InterceptorSkip = 'X-Skip-Interceptor';
export const TokenSessionStorageKey = 'sso-buddy-token-session';
export const StaticSessionStorageKey = 'sso-buddy-static-data';

@Injectable({ providedIn: 'root' })
export class AuthService {
    isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
    InterceptorSkipHeader = new HttpHeaders({
        'X-Skip-Interceptor': ''
    });

    sessionItems = [
        TokenSessionStorageKey,
        StaticSessionStorageKey
    ];

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.isUserLoggedIn$.next(this.isLoggedIn());
    }

    login(loginData: LoginRequest) {
        return this.http
        .post<LoginResponse>(
            environment.LOGIN_ENDPOINT,
            loginData,
            { headers: this.InterceptorSkipHeader });
    }

    logout(): void {
        this.sessionItems.forEach(item =>
            sessionStorage.removeItem(item));

        this.isUserLoggedIn$.next(false);
        this.router.navigate(['/login']);
    }

    getToken(): string {
        return sessionStorage.getItem(TokenSessionStorageKey)!;
    }

    getTokenData(value: keyof JwtToken): string {
        const jwt: string = this.getToken();
        const decodedJwt: any = jwt_decode(jwt);

        return decodedJwt[value];
    }

    fetchStaticData() {
        return this.http
            .get<StaticDataResponse>(environment.GET_STATIC_DATA_ENDPOINT);
    }

    getStaticData(value: keyof StaticData): any {
        const staticSessionData: StaticData = JSON.parse(sessionStorage.getItem(StaticSessionStorageKey)!);
        return staticSessionData[value];
    }

    isLoggedIn(): boolean {
        const token = sessionStorage.getItem(TokenSessionStorageKey);

        if (!token) {
            return false;
        }

        const expiry: any = +this.getTokenData('exp');
        const timeExpired = new Date().getTime() > expiry * 1000;

        return !timeExpired;
    }
}
