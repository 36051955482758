<div class="login-page-container">
    <div class="login-form-container">
        <form [formGroup]="loginForm" (submit)="onSubmit()" class="login-form">
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput formControlName="username">
                <mat-error
                    *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)">
                    Username is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="password">
                <mat-error
                    *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)">
                    Password is required
                </mat-error>
            </mat-form-field>

            <button
                mat-flat-button color="primary"
                class="login-button"
                [disabled]="loginForm.invalid || loginFormSubmitted"
                >
                    <span *ngIf="!loginFormSubmitted">LOGIN</span>
                    <span *ngIf="loginFormSubmitted">
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </span>
            </button>
            
            <a class="reset-password" 
                target="_blank" 
                matTooltip="MFA should be enabled for resetting your password"
                matTooltipPosition="after"
                href="{{ resetPasswordUrl }}">Forgot Password?</a>
        </form>
    </div>

    <div class="lion-graphic-container">
        <img src="assets/lion-graphic.png" alt="lion graphic image" />
    </div>
</div>
